<template>
    <div class="pb-1">
        <div class="card adv-card" v-for="user in users" :key="user.id">
            <div class="card-body">
                <div class="row d-flex justify-content-between align-items-center text-dark">
                    <div class="col-11">
                        <div class="row">
                            <div class="col-md-6">
                                <div><strong>{{ user.name }}</strong> - <small>{{ user.created_at | moment('Do MMMM YYYY') }}</small></div>
                                <div>{{ user.email }}</div>
                            </div>
                            <div class="col-md-3 d-flex align-items-center my-1 my-md-0">
                                <b-badge variant="primary">
                                    <span>{{ user.country }}</span>
                                </b-badge>
                            </div>
                            <div class="col-md-3 d-flex align-items-center my-1 my-md-0">
                                <b-badge v-if="user.verify == 1" variant="success">
                                    <span>Email Verification Completed</span>
                                </b-badge>
                                <b-badge v-else variant="danger">
                                    <span>Email Verification Incomplete</span>
                                </b-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { BCard, BCardHeader, BCardBody, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BButton, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BBadge,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            users: [],
        }
    },
    mounted() {
        this.fetchUsers()
    },
    methods: {
        fetchUsers() {
            this.$http.get('/users/pending').then((res) => {
                this.users = res.data
            })
        },
    },
}
</script>
  